import { Button, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { DateContext } from "../App";
import { DatePicker } from "../components/DatePicker";

const MotionButton = motion(Button);
export interface DateInputPageProps {}

export const DateInputPage: React.FC<DateInputPageProps> = () => {
  const { date, setDate, setSavedDate } = useContext(DateContext);

  return (
    <VStack spacing={8} mt={8}>
      <Text fontSize="100px">📅</Text>
      <Text>Enter your anniversary date</Text>
      <DatePicker value={date} onChange={(date: any) => setDate(date)} center />
      {date ? (
        <MotionButton
          leftIcon={<FaPaperPlane />}
          colorScheme="teal"
          variant="solid"
          onClick={() => {
            setSavedDate(date);
          }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Let's GO!
        </MotionButton>
      ) : undefined}
      {date?.valueOf() === new Date(2018, 5, 18).valueOf() ? (
        <motion.div
          animate={{ scale: [1, 1.5, 1] }}
          transition={{ repeat: Infinity, duration: 2 }}
        >
          <Text>❤️</Text>
        </motion.div>
      ) : undefined}
    </VStack>
  );
};
