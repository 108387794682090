import { readSavedDate } from "../utils/readSavedDate";
import { useStickyState } from "./useStickyState";

export interface UseSavedDateValue {
  date: Date | null;
}

export const useSavedDate = ({ date }: UseSavedDateValue) => {
  const [savedDate, setSavedDate] = useStickyState<Date | null>(
    date,
    "anniversaryDate"
  );
  return [readSavedDate(savedDate), setSavedDate];
};
