import { ChakraProvider, theme } from "@chakra-ui/react";
import * as React from "react";
import { MainPage } from "./pages/MainPage";

export const DateContext = React.createContext<any>(null);

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <MainPage />
    </ChakraProvider>
  );
};
