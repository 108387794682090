import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { DateContext } from "../App";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import { DatePicker } from "../components/DatePicker";
import { useSavedDate } from "../hooks/useSavedDate";
import { DateCalculationPage } from "./DateCalculationPage";
import { DateInputPage } from "./DateInputPage";

export interface MainPageProps {}

const MotionBox1 = motion(Box);
const MotionBox2 = motion(Box);

export const MainPage: React.FC<MainPageProps> = () => {
  const [date, setDate] = useState<Date>();
  const [savedDate, setSavedDate] = useSavedDate({ date: null });
  const withDate = savedDate && savedDate !== "";
  const bgGradient = useColorModeValue(
    "linear(to-b, gray.50, gray.300)",
    "linear(to-b, gray.600, gray.900)"
  );

  return (
    <DateContext.Provider value={{ date, savedDate, setDate, setSavedDate }}>
      <Box textAlign="center" fontSize="xl" bgGradient={bgGradient}>
        {console.log("bg", bgGradient)}
        <Box minH="100vh" p={3}>
          <Flex justifyContent="space-between">
            {withDate ? (
              <DatePicker
                value={savedDate}
                onChange={(date: any) => setSavedDate(date)}
              />
            ) : (
              <Box />
            )}
            <ColorModeSwitcher />
          </Flex>
          <AnimatePresence>
            {withDate ? (
              <MotionBox1
                initial={{ y: 300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -300, opacity: 0 }}
              >
                <DateCalculationPage />
              </MotionBox1>
            ) : (
              <MotionBox2
                initial={{ y: 300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -300, opacity: 0 }}
              >
                <DateInputPage />
              </MotionBox2>
            )}
          </AnimatePresence>
        </Box>
      </Box>
    </DateContext.Provider>
  );
};
