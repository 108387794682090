import { useColorMode } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import RDatePicker, {
  DatePickerProps as RDatePickerProps,
} from "react-date-picker";

const StyledDatePicker = styled(RDatePicker)<{
  $colorMode: string;
  $center?: boolean;
}>`
  svg {
    ${(props) => (props.$colorMode === "dark" ? "stroke: white" : undefined)};
  }
  .react-date-picker__calendar {
    ${(props) =>
      props.$center
        ? `left: 50% !important;
    transform: translateX(-50%) !important;`
        : undefined}
  }
  .react-calendar__month-view__days button {
    padding: 8px 0px !important;
  }
  ${(props) =>
    props.$colorMode === "dark"
      ? `button:focus,
  button:hover {
    background: hsl(0, 0%, 40%) !important;
  }
  button:disabled {
    background: hsl(0, 0%, 20%) !important;
  }
  .react-calendar__tile--now {
    background: hsl(100, 100%, 20%) !important;
  }
  .react-calendar {
    background: hsl(0,0%,30%);
  }
  `
      : undefined};
`;

export interface DatePickerProps extends RDatePickerProps {
  center?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({ center, ...rest }) => {
  const { colorMode } = useColorMode();
  return (
    <StyledDatePicker
      maxDate={new Date()}
      {...rest}
      $colorMode={colorMode}
      $center={center}
    />
  );
};
