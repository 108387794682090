import { Stat, StatLabel, StatNumber, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { DateContext } from "../App";
import { dateDiff } from "../utils/dateDiff";
import { monthDiff } from "../utils/monthDiff";

export interface DateCalculationPageProps {}

export const DateCalculationPage: React.FC<DateCalculationPageProps> = () => {
  const { savedDate } = useContext(DateContext);

  return (
    <VStack mt={16} spacing={8}>
      <Stat size="xl">
        <StatLabel>Days</StatLabel>
        <StatNumber>{dateDiff(savedDate, new Date())}</StatNumber>
      </Stat>
      <Stat size="xl">
        <StatLabel>Months</StatLabel>
        <StatNumber>{monthDiff(savedDate, new Date())}</StatNumber>
      </Stat>
    </VStack>
  );
};
